@import '../../global-settings';
.wrapper {
  max-width: 230px;
  cursor: pointer;
}
.studentName {
  @include header-small;
  font-size: 18px;
  position: relative;
  color: $color-white;
  text-align: center;
  margin: 0;
  z-index: 3;
  text-transform: capitalize;
}

.nameCard {
  position: relative;
  background-color: $color-dark-grey;
  padding: 15px;
  border-radius: 13px;
  top: -80px;
}
.hoverEdit {
  display: block;
  position: relative;
  width: 100%;
  top: -7.8rem;
  margin-top: 10px;
  z-index: 2;
  cursor: pointer;
  z-index: 999;

  // .editButton {
  //   background-color: #00ce00;
  //   border: 1px solid #00ba00;
  //   color: white;
  //   width: 50%;
  //   padding: 5px 10px;
  //   border-top-left-radius: 10px;
  //   border-bottom-left-radius: 10px;
  // }

  .deleteButton {
    background-color: #ce0000;
    //border: 1px solid #ba0000;
    color: white;
    width: 100%;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
  }
}

.avatar {
  width: 100px;
  z-index: 2;
  position: relative;
  top: -31px;
  display: block;
  margin: 0 auto;
}

.icon {
  font-size: 25px;
  color: black;
  text-align: center;
  margin: 0;
}
