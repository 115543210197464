@import '../../global-settings';
.wrapper {
  width: 100%;
  margin-top: 250px;
  padding: 10px;
}

.logo {
  margin: 20px auto 0;
  max-width: 300px;
  padding: 20px;
}

.body {
  background-color: $color-secondary;
  color: $color-white;
  width: 100%;
  position: relative;
  padding: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
}

.nameField {
  @include header-small;
  display: inline-block;
  background: $color-white;
  color: $color-light-grey;
  border: 3px solid $color-light-grey;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 32px;
  padding: 14px 3px;
  text-align: center;
  width: 48%;
  margin: 0 2% 0 auto;

  &::placeholder {
    color: $color-light-grey;
  }
}

.lastNameField {
  @include header-small;
  display: inline-block;
  background: $color-white;
  color: $color-light-grey;
  border: 3px solid $color-light-grey;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 32px;
  padding: 14px 3px;
  text-align: center;
  width: 48%;
  margin: 0 auto 0 2%;

  &::placeholder {
    color: $color-light-grey;
  }
}

.button {
  font-family: $primary-sans-serif-font;
  width: 100%;
  color: white;
  background: #F6C26D;
  font-size: 32px;

  &:hover {
    background: #ffd085;
  }
}

@media (max-width: 640px) {
  .nameField, .lastNameField {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }
}
