@import '../../global-settings';

.body {
  position: relative;
  display: block;
  background-color: $color-teacher-background-boxes;
  margin-right: 30px;
  padding: 15px;
  border-radius: 4px;
}
.checkbox {
  position: relative;
  @include standard-text;
  color: $color-white;
  padding-top: 3px;
  display: inline-block;
  padding-left: 40px;
  margin-left: 10px;
  margin-right: 20px;
  cursor: pointer;
  font-size: 22px;
  line-height: 26px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $color-teacher-background-boxes;
  border: 1px solid $color-white;
  border-radius: 4px;
}

.checkbox:hover input ~ .title {
  background-color: #4a4d6f;
}

.checkbox input:checked ~ .title {
  background-color: #2196f3;
}

.title:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .title:after {
  display: block;
}
.checkbox .title:after {
  left: 13px;
  top: -11px;
  width: 5px;
  height: 25px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
