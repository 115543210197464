@import '../../global-settings';

.container {
  position: relative;
  width: 100%;
}

.image {
  width: 400px;
}

.buttonContainer {
  position: relative;
  z-index: 4;
}

.speakerButton {
  position: fixed;
  bottom: 0%;
  width: 90px;
  right: 0%;
  background-color: transparent;
  margin: 10px;
  border: none;
  cursor: pointer;
}
