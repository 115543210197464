@import '../../global-settings';
.container {
  width: 100%;
  position: relative;
}

.teamWrapper {
  border-radius: 40px;
  width: 100%;
  margin-left: 18px;
  min-height: 100px;
  padding: 18px;
  border: 0;
  box-sizing: border-box;
  text-align: center;

  &.teamThunder {
    background-color: $color-thunder;
    color: $color-white;
    box-shadow: -18px 13px $color-thunder-shadow;
  }

  &.teamLightning {
    background-color: $color-lightning;
    color: $color-white;
    box-shadow: -18px 13px $color-lightning-shadow;
  }

  h1 {
    @include header-small;
    font-size: 80px;
  }
}

.header {
  position: relative;
  background-color: rgba(50, 50, 83, 0.4);
  border-radius: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .teamName {
    display: block;
    font-size: 60px;
    line-height: 60px;
    margin: 25px 0 25px 56px;
    font-weight: 400;
  }
}

.score {
  background-color: $color-secondary;
  border-radius: 80px;
  text-align: center;
  padding-right: 80px;
  padding-left: 80px;

  .teamScore {
    @include header-small;
    font-size: 80px;
    line-height: 90px;
    margin: 10px 0 0;
  }
}

.contain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 5px;
}

.chooserAvatar {
  height: 280px;
}

.chooserText {
  @include header-large;
}
