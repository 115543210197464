@import '~foundation-sites/scss/foundation';
@include foundation-xy-grid-classes;
@import '../global-settings';

.textWhite {
  color: $color-white;
}

.chooserAlien {
  @media screen and (min-width: 1024px) {
    height: 150px;
    width: 150px;
  }
}

.whoWill {
  font-size: 25px;
  @media screen and (min-width: 1024px) {
    font-size: 35px;
  }
}

.centerText {
  text-align: center;
}
.rightText {
  text-align: right;
}
.rightPadding {
  padding-right: 15px;
}
.leftPadding {
  padding-left: 15px;
}
.rightText {
  text-align: center;
}

.vAlignTextTop {
  vertical-align: text-top;
}

.underlineText {
  text-decoration: underline;
}

.gameId {
  background-color: #1e1e24;
  border-radius: 20px;
  margin: 10px 20px;
  width: 350px;
  float: left;

  p {
    padding: 10px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
}

.gameCodeInstructionWrapper {
  //border-bottom: 2px solid $color-dark-grey;
  padding-top: 10px;

  &.fullScreen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    margin: 0;
    padding-top: 25vh;
    background-color: $color-background;
    z-index: 999;

    h2 {
      font-size: 40px;
      line-height: 40px;
      display: block;

      .fontSize46 {
        display: block;
        font-size: 100px;
        line-height: 120px;
        margin-bottom: 30px;
      }
    }

    h3 {
      font-size: 90px;
      line-height: 90px;
    }

    .gotoText {
      display: block;
    }
  }

  .gotoText {
    display: none;
  }
}

.leftText {
  text-align: left;

  * {
    margin-left: 0 !important;
  }
}

.rightText {
  text-align: right;

  * {
    margin-right: 0 !important;
  }
}

.fullWidth {
  display: inline-block;
  width: 100%;
}

.fixedBottom {
  position: fixed;
  bottom: 0;
  left: 0;
}

.paddingTop {
  padding-top: 20px;
}

.paddingBottom {
  padding-bottom: 20px;
}

.paddingTopLarge {
  padding-top: 40px;
}

.paddingTopExtraLarge {
  padding-top: 80px;
}

.paddingRight {
  padding-right: 20px;
}

.paddingRightLarge {
  padding-right: 50px;
}

.marginSmall {
  margin: 12px;
}

.marginTopSmall {
  margin-top: 12px;
}

.displayInlineBlock {
  display: inline-block;
}

.headerLarge {
  @include header-large;
  font-size: 50px;
}

.mobileHeaderLargeReduce {
  @media (max-width: 640px) {
    font-size: 30px;
  }
}

.headerMedium {
  @include header-small;
  font-size: 25px;
}

.gameCodeWrapper {
  @include header-large;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0;
  letter-spacing: 2px;
  font-size: 80px;
}

.playerCountWrapper img {
  margin-right: 5px;
}

.playerCountWrapper {
  @include header-large;
  font-size: 30px;
  line-height: 30px;
  margin-top: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.playerCountInner {
  background-color: $color-secondary;
  margin: 0;
  padding: 10px;
}

.objectFitCover {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

.playersJoinedWrapper {
  @include header-large;
  font-size: 20px;
  line-height: 20px;
  margin-top: 5px;
  padding-top: 0;
  display: block;
}

.zoinksLogoAnimate {
  animation: zoomIn 0.5s linear;
}

.marginTop {
  margin-top: 36px;
}

.marginTopLarge {
  margin-top: 72px;
}

.noPadding {
  padding: 0;
}

.marginBottom {
  margin-bottom: 36px;
}

.marginBottomLarge {
  margin-bottom: 72px;
}

.noMarginTop {
  margin-top: 0;
}

.noPaddingTop {
  padding-top: 0;
}

.fontSize26 {
  font-size: 26px;

  @media (max-width: 640px) {
    font-size: 20px;
  }
}
.fontSize30 {
  font-size: 30px;

  @media (max-width: 640px) {
    font-size: 26px;
  }
}

.lineHeight26 {
  line-height: 26px;

  @media (max-width: 640px) {
    line-height: 20px;
  }
}

.lineHeight36 {
  line-height: 36px;

  @media (max-width: 640px) {
    line-height: 26px;
  }
}

.fontSize36 {
  font-size: 36px;

  @media (max-width: 640px) {
    font-size: 26px;
  }
}

.fontSize46 {
  font-size: 46px;

  @media (max-width: 640px) {
    font-size: 36px;
  }
}

.fontSize46 {
  font-size: 55px;

  @media (max-width: 640px) {
    font-size: 39px;
  }
}

.standardLogo {
  margin-top: 16px;
  width: 200px;
}

.displayBlock {
  display: block;
}

.teacherArea {
  background-color: #1e1e24;
  margin: 0;
}

.rocksBackgroundPositioning {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  min-height: 100vh;
}

.removeMarginBottom {
  margin-bottom: 0;
}

hr {
  margin: 30px 0 30px 0;
}

.margin-left-2 {
  margin-left: 65px;
}

.moveItemUp {
  position: relative;
  top: -84px;
}
.largeQuestionButton {
  width: 900px;
  height: 200px;
  background: white;
  color: black;
  font-size: 50px;
  border: 6px solid $color-secondary;
  border-radius: 15px;
  text-transform: capitalize;
  font-weight: bold;
}

.leaveFeedbackButton {
  position: relative;
  width: 200px;
  background: white;
  color: black;
  font-size: 15px;
  border: 6px solid $color-secondary;
  border-radius: 15px;
  text-transform: capitalize;
  font-weight: bold;
  display: inline-block;
  z-index: 2;
}

@media only screen and (min-width: 650px) and (max-height: 750px) {
  .leaveFeedbackButton {
    position: fixed;
    right: 0;
    top: 0;
    width: 200px;
    background: white;
    color: black;
    font-size: 15px;
    border: 6px solid $color-secondary;
    border-radius: 15px;
    text-transform: capitalize;
    font-weight: bold;
    display: inline-block;
    z-index: 2;
    margin-right: 15px;
  }
}

@media only screen and (max-height: 750px) and (max-width: 650px) {
  .leaveFeedbackButton a {
    text-decoration: none;
    display: block;
    line-height: 1px;
  }

  .leaveFeedbackButton {
    margin-top: -50px;
    position: relative;
    width: 200px;
    background: white;
    color: black;
    font-size: 15px;
    border: 6px solid $color-secondary;
    border-radius: 15px;
    text-transform: capitalize;
    font-weight: bold;
    display: inline-block;
    z-index: 2;
  }
}

.leaveFeedbackButton a {
  text-decoration: none;
}

.tutHeader {
  width: 100%;
  position: relative;

  .leftHeader {
    left: 0;
    padding-left: 20px;

    h2 {
      @include header-small;
      color: white;
      font-size: 40px;
    }
  }
  .rightHeader {
    position: absolute;
    right: 15px;
    top: -14px;

    .yellowButton {
      @include header-small;
      width: 130px;
      height: 50px;
      background-color: #f6c26d;
      border-radius: 4px;
      border: none;
      color: white;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
  }
}

.startHeader {
  .hidden {
    display: none;
  }
  .rightHeader {
    position: absolute;
    right: 15px;
    top: -14px;

    .yellowButton {
      @include header-small;
      width: 130px;
      height: 50px;
      background-color: #f6c26d;
      border-radius: 4px;
      border: none;
      color: white;
      position: relative;
      z-index: 2;
      cursor: pointer;
      margin-top: 16px;
    }
    @media (max-width: 640px) {
      right: 15px;
      top: 15px;
    }
  }
}
.tutContainer {
  @media (max-width: 640px) {
    background-color: #1e1e24;

    height: 100vh;
    position: fixed;
    top: 0px;
  }
}

.help {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 25px;

  &.fixed {
    position: fixed;
  }

  .helpIcon {
    width: 35px;
    top: auto;
    right: 100px;
  }
  .helpButton {
    @include header-small;
    color: white;
    position: relative;
    background-color: transparent;
    margin-right: 75px;
    border: none;
    padding: 4px;
    z-index: 3;
    cursor: pointer;

    span {
      position: relative;
      top: -10px;
    }
  }
}

.intro {
  height: 100vh;
  z-index: 1;
  background-color: #1e1e24;

  .introImage {
    width: 100%;
    position: relative;
    top: -86px;
  }

  @media (max-width: 640px) {
    height: 50vh;
    margin: 25vh auto 0;
  }
}

.greyAvatar {
  filter: grayscale(100%);
}

.sectionBckQuestion {
  .time {
    background-color: #27293b;
    /* padding: 12px 10px; */
    border-radius: 10px;
    padding-left: 22px;
  }
}

.tutBody {
  display: block;
  margin: 0 auto;
  .stepBody {
    text-align: center;
    color: white;

    p {
      width: 800px;
      font-size: 28px;
      line-height: 38px;
      display: block;
      margin: 35px auto;

      @media (max-width: 640px) {
        padding: 30px;
        text-align: center;
        width: 100%;
        font-size: 16px;
        line-height: 30px;
      }
    }

    .stepImage {
      width: 700px;
      //height: 400px;

      @media (max-width: 640px) {
        width: 100%;
        padding: 20px;
      }
    }
  }
}

.largeTxt {
  @include body-text;
  font-size: 100px;
  line-height: 95px;
}
.mediumTxt {
  @include body-text;
  font-size: 70px;
  line-height: 95px;
}

.questionSummary {
  @include standard-text;
  font-size: 14px;
  padding: 0px;
  width: calc(100% - 20px);
  background-color: $color-white;
  border: 1px solid $color-light-grey;
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 150px;
  margin: 12px 10px;
  cursor: pointer;
  opacity: 0.8;

  &.active {
    opacity: 1;
    border: 3px solid #3562eb;
  }

  &.addNewQuestion {
    opacity: 1;
    border: 3px solid #3562eb;
    background-color: #3562eb;
    font-size: 18px;
    padding-top: 20px;

    .padding {
      text-align: center;
      font-size: 60px;
      color: $color-white;
    }

    &.disabledQuestion {
      border: 3px solid #524e4e;
      background-color: #524e4e;
      cursor: not-allowed;
    }
  }

  .padding {
    padding: 10px;
  }
}

.questionSummaryNumber {
  background-color: #3562eb;
  color: white;
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 10px;
}

.disabledQuestion .questionSummaryNumber {
  background-color: #524e4e;
}

.activeQuest {
  background-color: #3562eb;
  border: 2px solid #3562eb;
  color: white;
  width: 100%;
  text-align: center;
  display: block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.teacherBoxesBackground {
  background-color: $color-teacher-background-boxes;
}
.questionAnswered {
  //background-color: #b4ce83;
  //box-shadow: -5px 5px #6e804c;
  //padding: 15px 10px;
}

.questionNotAnswered {
  background-color: #ce0000;
  color: white;
  box-shadow: -5px 5px #850202;
  padding: 15px 10px;
}
.bluffed {
  background-color: #b5cce9;
  box-shadow: -5px 5px #5e9ae4;
  padding: 15px 10px;
}

.choicesSection {
  background-color: $color-teacher-background-boxes;
  padding: 20px;
  border-radius: 10px;
  width: 400px;

  input {
    @include header-small;
    width: 100%;
    background: $color-white;
    color: $color-light-grey;
    border: 5px solid $color-light-grey;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-size: 20px;
    padding: 10px;
    text-align: left;
    margin-bottom: 5px;

    &::placeholder {
      color: $color-light-grey;
    }
  }
}

.evenQuestionsBar {
  @include body-text;
  width: 100%;
  background-color: $color-light-grey;
  color: $color-dark-grey;
  margin: 0;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  margin-top: 10px;
}

.minFullHeight {
  min-height: 100vh;
}

.align-content-center {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.invisible-input {
  @include body-text;
  width: 500px;
  height: 100%;
  background: transparent;
  border: none;
  color: white;
  font-size: 30px;
  outline: none;
  padding-left: 20px;
}

.invisible-input::placeholder {
  color: rgba(225, 225, 225, 0.8);
}

.student-list-padding {
  padding-top: 0;
}

.inLine {
  display: flex;
}

.logo {
  margin: 20px auto 0;
  max-width: 300px;
  padding: 20px;
}

.flex {
  display: flex;
}

.mde-tabs {
  display: none !important;
}

.mde-header {
  background-color: $color-secondary !important;
}

.react-mde {
  border: 0 !important;
}

.mde-header-item button {
  color: $color-white !important;
}

ul.contains-task-list {
  padding-left: 0;
}

ul.contains-task-list li {
  list-style-type: none;
}

.pointsWrapperOuter {
  padding-top: 200px;
  text-align: center;
  width: 100%;
}

.oneScreen {
  width: 100%;
  height: 100%;
  position: fixed;
}

.pointsWrapper {
  @include header-large;
  background-color: $color-secondary;
  margin: 0;
  padding: 10px 30px;
  border-radius: 4px;
  color: $color-white;
  font-size: 50px;
  display: inline-block;
}

.editor-wrapper {
  h3 {
    padding: 0;
  }
}

.questionSummary {
  h3 {
    padding: 0;
    text-align: left;
  }
}

.multi-choice {
  width: calc(50% - 2px);
  min-height: 125px;
  display: inline-block;
  margin: 1px;
  padding: 20px;

  &.multi-choice-option-0 {
    border-top-left-radius: 20px;
    background: rgba(141, 138, 222, 0.6);
    @media (max-width: 640px) {
      border-radius: 20px;
    }
  }

  &.multi-choice-option-1 {
    border-top-right-radius: 20px;
    background: rgba(80, 166, 218, 0.6);
    @media (max-width: 640px) {
      border-radius: 20px;
    }
  }

  &.multi-choice-option-2 {
    border-bottom-left-radius: 20px;
    background: rgba(226, 76, 73, 0.6);
    @media (max-width: 640px) {
      border-radius: 20px;
    }
  }

  &.multi-choice-option-3 {
    border-bottom-right-radius: 20px;
    background: rgba(93, 182, 39, 0.6);
    @media (max-width: 640px) {
      border-radius: 20px;
    }
  }

  &.selected {
    border: 2px solid $color-primary;
  }

  @media (max-width: 640px) {
    width: 100%;
    min-height: 100px;
  }
}

.editor-body {
  background-color: white;
  color: black;
  border-radius: 2px;
  padding: 5px 10px;
}

@media (max-width: 640px) {
  .objectFitCover {
    object-fit: contain;
  }
}

.accordion {
  width: 100%;
  display: block;

  .accordion-title,
  .accordion-document {
    display: block;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 12px;
  }

  .accordion-title {
    position: relative;
    background-color: $color-secondary;
    color: #ffffff;
    border-radius: 5px;
    font-weight: bold;
    font-size: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;

    &:after {
      position: absolute;
      width: 50px;
      height: 50px;
      right: 12px;
      top: 12px;
      content: '\25bc';
      color: #ffffff;
    }

    &:not(.open) {
      margin-bottom: 40px;
    }
  }

  .accordion-document {
    background-color: #ffffff;
    color: $color-dark-grey;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 28px;
    display: none;

    &.open {
      display: block;
      margin-bottom: 40px;
    }
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wave {
  width: 5px;
  height: 18px;
  background: #fff;
  margin: 3px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

.largeLoader .wave {
  height: 40px;
  margin: 8px;
}

.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(0.25);
  }
  50% {
    transform: scale(0.5);
  }
  75% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
