@import '../../global-settings';

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modalContainer {
  background-color: transparent;
  display: block;
  margin: auto;
  width:40%;
  min-height: 300px;
  color: white;
}

.modalContent {
    text-align: center;
}

.close {
  background-color: #fd3d3d;
  color: white;
  padding: 5px;
  position: relative;
  float: right;
  border-radius: 5px;
}

.inputField {
  @include header-small;
  font-size: 32px;
  line-height: 32px;
  background: $color-white;
  color: $color-dark-grey;
  border: 3px solid $color-light-grey;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 25px;
  height: 10px;
  width: 50%;
  box-sizing: border-box;
  margin-top: 40px;

  &::placeholder {
    color: $color-light-grey;
  }
}
