@import '../../global-settings';

.container {
  position: relative;
  width: 100%;
}

.body {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 164px;
  background-color: $color-teacher-background-boxes;
  border-radius: 14px;
  box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.1);
}

.avatarContainer {
  position: relative;
  padding: 10px;
  height: 135px;
  margin: 16px 10px 10px;
  background-color: #434371;
  border-radius: 14px;
  box-sizing: border-box;
}

.avatar {
  box-sizing: border-box;
  height: 100%;
  margin-top: 0;
}

.setName {
  position: relative;
  width: 100%;
}

.name {
  @include standard-text;
  text-align: left;
  color: white;
  font-size: 25px;
  line-height: 28px;
  margin-bottom: 0;
  margin-top: 30px;
  padding-left: 10px;
  padding-top: 0;
}

.arrow {
  width: 20px;
  display: inline-flex;
}

.launchButton {
  position: relative;
  width: 100%;
}

.launch  {
  @include button;
  font-family: $primary-sans-serif-font;
  background: #BBD686;
  border: 1px solid #BBD686;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 7px 1px 0 rgba(147,171,100,1);
  float: right;
  margin-right: 10px;
  margin-top: 20px;
}

.edit  {
  @include button;
  font-family: $primary-sans-serif-font;
  background: $color-primary;
  border: 1px solid #a07e3c;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
  color: $color-white;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 7px 1px 0 rgb(160, 126, 60);
  float: right;
  margin-right: 10px;
  margin-top: 20px;
}

.delete  {
  @include button;
  font-family: $primary-sans-serif-font;
  background: $color-incorrect;
  border: 1px solid #641c1c;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
  color: $color-white;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 7px 1px 0 rgb(100, 28, 28);
  float: right;
  margin-right: 10px;
  margin-top: 20px;
  clear: both;
}



