@import '../../../global-settings';

.container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 50px auto 0;

  .wowzaTitle {
    font-size: 30px;
    margin-bottom: 150px;
    color: $color-white;
    width: 100%;
    text-align: center;
  }

  .purpleWave {
    position: absolute;
    top: 25vh;
    left: 0;
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  .avatarLeft {
    position: absolute;
    top: 40px;
    width: 100px;
    left: -300px;
    animation: astro 20s infinite linear;
  }

  .avatarRight {
    position: absolute;
    bottom: 40px;
    width: 100px;
    right: -300px;
    animation: astro 15s infinite backwards;
  }

  .avatarRightTwo {
    position: absolute;
    top: 20px;
    width: 100px;
    right: -150px;
    animation: astro2 25s infinite backwards;
  }

  .avatarLeftTwo {
    position: absolute;
    bottom: 10px;
    width: 100px;
    left: -425px;
    animation: astro2 20s infinite linear;
  }

  .moonRockLeft {
    position: absolute;
    top: 20px;
    width: 100px;
    left: -100px;
    animation: floaty 30s infinite linear;
  }

  .moonRockRight {
    position: absolute;
    bottom: 20px;
    width: 100px;
    right: -100px;
    animation: floaty 30s infinite backwards;
  }

  .moonRockTwoLeft {
    position: absolute;
    top: 300px;
    width: 150px;
    left: -200px;
    animation: floaty 25s infinite linear;
  }

  .moonRockTwoRight {
    position: absolute;
    bottom: 150px;
    width: 190px;
    right: -250px;
    animation: floaty 25s infinite linear;
  }

  .body {
    position: relative;
    background-color: $color-white;
    border-radius: 15px;
    margin: 0 auto;
    z-index: 1;
    padding: 10px;
    min-height: 120px;

    .header {
      position: absolute;
      top: -100px;
      left: 10%;
      display: block;
      width: 80%;
      height: 215px;
      background-color: $color-white;
      border-radius: 50%;
      margin: 0 auto;
      z-index: -1;
    }

    .logo {
      display: block;
      width: 70%;
      max-width: 300px;
      position: relative;
      margin: -50px auto 0;
    }
  }
}

.errorList li {
  @include standard-text;
  font-size: 14px;
  font-weight: 400;
}

.textBody {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;

  .inputField {
    @include standard-text;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    width: 100%;
    height: 38px;
    border: 1px solid #2B3553;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 5px 5px 10px;
    margin-top: 25px;
  }

  .inputFieldPassword {
    @include standard-text;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    width: 100%;
    height: 38px;
    border: 1px solid #2B3553;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 5px 5px 10px;
  }

  .openButton {
    width: 100%;
    margin-left: 0;
    height: 54px;
    padding-top: 7px;
    background: linear-gradient(0deg, #365DEB 0%, #3E8DF0 100%);
    border-radius: 6px;
    text-transform: none;
    font-size: 25px;
  }

  .link {
    @include standard-text;
    text-transform: uppercase;
    text-align: center;
    display: block;
    text-decoration: none;
    font-size: 12px;
  }

}

@keyframes floaty {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  33% {
    transform: translate(-40px, 40px) rotate(180deg);
  }
  66% {
    transform: translate(40px, -40px) rotate(360deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes astro {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  33% {
    transform: translate(-80px, 80px) rotate(30deg);
  }
  66% {
    transform: translate(80px, -80px) rotate(-20deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes astro2 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  33% {
    transform: translate(30px, -50px) rotate(30deg);
  }
  66% {
    transform: translate(-30px, 50px) rotate(-30deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
