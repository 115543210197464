@import 'global-settings';

body {
  background-color: $color-background;
  margin: 0;
}

.small-text {
  @include small-text;
}

.body-text {
  @include body-text;
}

.header-small {
  @include header-small;
}

.header-large {
  @include header-large;
}
