@import '../../global-settings';
.wrapper {
  width:100%;
  position: relative;
  height: 100vh;
}
.wrap {
  position: relative;
}

.grid {
  //margin-top: 45px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 115px;
  background-color: $color-secondary;
}

.timer {
  transform: scale(0.6);
  position: relative;
  top: -40px;
}

.button {
  font-family: $primary-sans-serif-font;
  width: 100%;
  background: #ffd085;
  font-size: 32px;
  text-transform: none;
  color: $color-dark-grey;

}

.logo {
  padding-bottom: 45px;
}
