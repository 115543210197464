@import '../../global-settings';
.mainContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  .avatar {
    margin-right: 10px;
  }
}
