@import '../../global-settings';

.container {
  width: 100%;
  position: relative;
}

.body {
  position: relative;
  width: 240px;
  height: 600px;
  background-color: $color-teacher-background-boxes;
  border-radius: 8px;
  padding: 20px;
}

.icons {
  width: 30px;
}

.questionIcons {
  width: 15px;
}
.supportIcon {
  width: 20px;
  padding-right: 14px;
  margin-left: 25px;
}


.name{
  @include standard-text;
  text-align: left;
  color: white;
  font-size: 20px;
  margin-bottom: 0px;
  padding-left: 20px;
  margin-top: 5px;
}

.question {
  @include standard-text;
  text-align: left;
  color: white;
  font-size: 16px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-left: 20px;
  margin-top: 5px;
}
.support {
  @include standard-text;
  text-align: left;
  color: white;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 5px;
  padding-top: 0;
  padding-right: 80px;
}

.circle {
  padding: 20px 10px 15px 0;
  margin-right: 10px;
  display: inline-block;
}

.circleNotVisible {
  padding: 20px 17px 15px 0;
  margin-right: 10px;
  visibility: hidden;
  display: inline-block;
}

.header {
  display: inline-flex;
  border-bottom: 2px solid white;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.questionSec {
  display: inline-flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.supportSec {
  display: inline-flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

a img, a p{
  display: inline-block;
}
