@import '../../global-settings';

.container {
  position: relative;
  width: 100%;
}

.image {
  width: 300px;
}
