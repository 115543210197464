@import '../../global-settings';

.card {
  position: absolute;
  top: -30px;
  left: 0;
  background-color: $color-secondary;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  z-index: -1;
  height: 60px;
}

.avatar {
  width: 280px;
  position: absolute;
  display: block;
  margin: 0 auto;
  top: -130px;
  left: 50%;
  transform: translateX(-50%);
}
