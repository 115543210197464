@import '../../global-settings';
.container {
  width: 100%;
  margin-top: 40px;
}

.container h3 {
  @include header-large;
  font-size: 30px;
  color: $color-white;
}

.question {
  width: 950px;
  display: block;
  margin: 0 auto;
  border: 3px solid $color-light-grey;
  border-radius: 20px;
}

.teams {
  display: flex;
  margin: 0 auto;
}

.wrapper {
  margin-top: 90px;
  display: flex;
}

.multi {
  width: calc(50% - 2px);
  min-height: 125px;
  display: inline-block;
  margin: 1px;
  padding: 20px;

  &.multi-choice-option-0 {
    border-top-left-radius: 20px;
    background: rgba(141, 138, 222, 0.6);
    @media (max-width: 640px) {
      border-radius: 20px;
    }
  }

  &.multi-choice-option-1 {
    border-top-right-radius: 20px;
    background: rgba(80, 166, 218, 0.6);
    @media (max-width: 640px) {
      border-radius: 20px;
    }
  }

  &.multi-choice-option-2 {
    border-bottom-left-radius: 20px;
    background: rgba(226, 76, 73, 0.6);
    @media (max-width: 640px) {
      border-radius: 20px;
    }
  }

  &.multi-choice-option-3 {
    border-bottom-right-radius: 20px;
    background: rgba(93, 182, 39, 0.6);
    @media (max-width: 640px) {
      border-radius: 20px;
    }
  }

  &.selected {
    border: 2px solid $color-primary;
  }

  @media (max-width: 640px) {
    width: 100%;
    min-height: 100px;
  }
}
