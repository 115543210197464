@import '../../global-settings';
.wrapper {
  width:100%;
}

.question {
  margin-top: 30px;
  margin-bottom: 400px;
}

.noAnswer {
  margin-top: 50px;
}
