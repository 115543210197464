@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


//TYPOGRAPHY
$primary-sans-serif-font: 'Open Sans', sans-serif;
$standout-sans-serif-font: 'Open Sans', sans-serif;
$standard-sans-serif-font: 'Poppins', sans-serif;

@mixin small-text {
  font-family: $primary-sans-serif-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

@mixin body-text {
  font-family: $primary-sans-serif-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

@mixin header-small {
  font-family: $primary-sans-serif-font;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}
@mixin standard-text {
  font-family: $standard-sans-serif-font;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  font-style: normal;
  letter-spacing: 0em;

}

@mixin header-large {
  font-family: $standout-sans-serif-font;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
}

@mixin button {
  border: 1px solid #324166;
  font-family: $standard-sans-serif-font;
  background: linear-gradient(0deg, #2C3854 0%, #324166 100%);
  padding: 20px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  display: block;
  margin: 15px auto;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
}

strong {
  font-weight: 700;
}

h1 {
  @include header-large;
}

h2 {
  @include header-small;
}

p {
  @include body-text;
}

small {
  @include small-text;
}

.button {
  @include button;
}

* {
  box-sizing: border-box;
}

.full-width-avatar {
    width: 180px;
    display: block;
    margin: 0 auto;
    padding-top: 75px;
}
//COLOURS
$color-background: #434371;
$color-primary: #d7a853;
$color-secondary: #323253;
$color-question-background: #F5B347;

$color-thunder: #5b5bbe;
$color-thunder-shadow: #373779;
$color-lightning: #EB6B6F;
$color-lightning-shadow: #a03839;

$color-correct-background: #BBD686;
$color-partially-correct-background: #BCD3F2;
$color-incorrect-background: #E37B80;
$color-correct: #3F826D;
$color-partially-correct: #006DAA;
$color-incorrect: #c83738;

$color-white: #ffffff;
$color-light-grey: #C4C4C4;
$color-dark-grey: #333333;

$color-teacher-background-boxes: #27293B;
