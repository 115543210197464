@import '../../global-settings';

.container {
  text-align: center;
  margin-top: 15%;
  margin-bottom: 15%;
}

.image {
  width: 147px;
  @media (max-width: 500px) {
    width: 110px;
  }
}

.avatar {
  position: relative;
  top: -238px;
  display: block;
  margin: 0 auto;
  @media (max-width: 500px) {
    top: -140px;
  }
}

.ribbon {
  max-height: 300px;
  @media (max-width: 500px) {
    max-height: 175px;
  }
}

.yellowText {
  color: #f6c26d;
}

.teamName {
  text-align: center;
}
.header {
  @include header-large;
  color: white;
  font-size: 70px;
  @media (max-width: 500px) {
    font-size: 40px;
  }
}
.smallText {
  @include body-text;
  font-size: 30px;
  color: white;
  @media (max-width: 500px) {
    font-size: 15px;
  }
}

.avatarName {
  background-color: #333333;
  max-width: 176px;
  min-height: 33px;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  display: block;
  margin: 0 auto;
  top: -80px;

  @media (max-width: 500px) {
    max-height: 53px;
    border-radius: 10px;
    padding: 4px;
    top: -60px;
    max-width: 120px;
  }

  p {
    color: white;
    font-size: 22px;
    margin-top: 10px;
    text-align: center;

    @media (max-width: 500px) {
      font-size: 17px;
      margin-bottom: 10px;
    }
  }
}
