@import '../../global-settings';
.wrapper {
  width: 100%;
  height: 235px;
  position: relative;
  overflow: hidden;

  &.hidePartiallyCorrect {
    .correct, .incorrect {
      width: 52%;
    }
  }
}

.partiallyCorrect {
  width: 34%;
  margin-left: 33%;
  height: 100%;
  padding: 30px 20px;
  border-top: 7px solid $color-dark-grey;
  border-left: 7px solid $color-dark-grey;
  border-right: 7px solid $color-dark-grey;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  position: absolute;
  z-index: 2;

  .nextLine {
    width: 100%;
    display: block;
  }

  &.partiallyCorrect {
    background-color: $color-partially-correct-background;
  }

  &.hidden {
    display: none;
  }

  .title {
    @include header-small;
    font-size: 45px;
    line-height: 70px;
    margin: 0;
    text-align: center;
    color: $color-dark-grey;
  }

}

.correct {
  position: absolute;
  height: 100%;
  width: 34%;
  left: -14px;
  z-index: 1;
  //transform: rotateY(180deg);
  border-top: 7px solid $color-dark-grey;
  border-left: 7px solid $color-dark-grey;
  border-right: 7px solid $color-dark-grey;
  border-radius: 10px;
  cursor: pointer;


  &.correct {
    background-color: $color-correct-background;
  }
  .title {
    @include header-small;
    font-size: 40px;
    line-height: 70px;
    margin: 0;
    text-align: center;
    color: $color-dark-grey;
  }

}
.incorrect {
  position: absolute;
  height: 100%;
  width: 34%;
  right: -14px;
  z-index: 1;
  border-top: 7px solid $color-dark-grey;
  border-left: 7px solid $color-dark-grey;
  border-right: 7px solid $color-dark-grey;
  border-radius: 10px;
  cursor: pointer;

  &.incorrect {
    background-color: $color-incorrect-background;
  }
  .title {
    @include header-small;
    font-size: 40px;
    line-height: 70px;
    margin: 0;
    text-align: center;
    color: $color-dark-grey;
  }
}
