@import '../../global-settings';

.box {
  position: absolute;
  display: block;
  background: lightblue;
  margin-bottom: 1em;
}

.fade-in {
  height: 150px;
  width: 1px;
  opacity: 0;
  transition: all;
  margin-left: -250px;
}

.versus {
  font-size: 50px;
  float: right;
}

.versus2 {
  font-size: 50px;
}

.fadeIn.show {
  opacity: 1;
  height: 150px;
  width: 500px;
  transition-property: width;
  transition-duration: 2s;
}

.fadeIn.dontShow {
  opacity: 1;
  height: 150px;
  width: 0px;
  left: 0;
  transition-property: width;
  transition-duration: 2s;
}

.versusScreen {
  top: 50%;
}

.split {
  top: 50%;
  margin: 0;
  position: absolute;
  -ms-transform: translateY(200%);
  transform: translateY(200%);
  height: 20%;
  width: 0%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
  transition-property: width;
  transition-duration: 2s;
}

.splitShow {
  width: 50%;
  transition-property: width;
  transition-duration: 2s;
}

.teamThunder {
  left: 0;
  background-color: $color-thunder;
  color: $color-white;
  box-shadow: -18px 13px $color-thunder-shadow;
}

.teamLightning {
  right: 0;
  background-color: $color-lightning;
  color: $color-white;
  box-shadow: -18px 13px $color-lightning-shadow;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.centered img {
  width: 50px;

  @media screen and (min-width: 1680px) {
    width: 100px;
  }
}

.centered2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-around;
}

.teamWrapper {
  display: block;
  margin: 10px;

  p,
  h2 {
    @media screen and (min-width: 1680px) {
      font-size: 30px;
    }
  }
}

.centered2 img {
  width: 50px;

  @media screen and (min-width: 1680px) {
    width: 100px;
  }
}
