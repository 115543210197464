@import '../../global-settings';
.container {
  width: 100%;
  position: relative;
  //margin-top: 40px;
}

.teamWrapper {
  @include header-small;
  font-size: 80px;
  border-radius: 40px;
  width: 666px;
  height: 95px;
  margin-left: 18px;
  margin-bottom: 13px;
  border: 0;
  display: inline-block;

  .avatar {
    position: relative;
    width: 18%;
    top: -115%;
    margin-left: 4%;
  }
  .avatarTwo {
    position: relative;
    width: 18%;
    top: -80%;
  }
  .avatarThree {
    position: relative;
    width: 18%;
    top: -115%;
  }
  .avatarFour {
    position: relative;
    width: 18%;
    top: -80%;
  }
  .avatarFive {
    position: relative;
    width: 18%;
    top: -115%;
  }

  &.teamThunder {
    background-color: $color-thunder;
    color: $color-white;
    box-shadow: 3px 9px $color-thunder-shadow;
  }

  &.teamLightning {
    background-color: $color-lightning;
    color: $color-white;
    box-shadow: 3px 9px $color-lightning-shadow;
  }

}


.footer {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 60px;

  .teamName {
    font-size: 39px;
    margin-top: 0;
    margin-left: 18px;
    color: $color-white;

  }
}


