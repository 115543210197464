@import '../../global-settings';
.wrapper {
  width: 100%;
  margin-top: 250px;
  padding: 10px;
}

.logo {
  max-width: 300px;
  padding: 20px;
  margin: 20px auto 0;
}

.body {
  background-color: $color-secondary;
  color: $color-white;
  width: 100%;
  position: relative;
  padding: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
}

.enterCodeField {
  @include header-small;
  background: $color-white;
  color: $color-light-grey;
  border: 3px solid $color-light-grey;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 32px;
  padding: 14px 3px;
  text-align: center;
  width: 100%;
  display: block;
  margin: 0 auto;

  &::placeholder {
    color: $color-light-grey;
  }
}

.button {
  font-family: $primary-sans-serif-font;
  width: 100%;
  color: white;
  background: #f6c26d;
  font-size: 32px;

  &:hover {
    background: #ffd085;
  }
}

.errorMessage {
  background-color: #b54747;
  color: white;
  font-weight: bold;
  padding: 15px;
  display: block;
  margin: 0 auto;
  text-align: center;
}
