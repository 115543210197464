@import '../../global-settings';

.standoutTitle {
  @include header-small;
  font-size: 80px;
  line-height: 80px;
  border-radius: 40px;
  padding: 70px;
  margin-left: 18px;
  margin-bottom: 13px;
  background-color: $color-white;
  box-shadow: -18px 13px $color-light-grey;
  border: 0;
  display: inline-block;

  &.teamThunder {
    background-color: $color-thunder;
    color: $color-white;
    box-shadow: -18px 13px #47479a;
  }

  &.teamLightning {
    background-color: $color-lightning;
    color: $color-white;
    box-shadow: -18px 13px #CE5255;
  }

  &.custom {
    background-color: #006DAA;
    color: $color-white;
    box-shadow: -18px 13px #01466d;
  }

  &.small {
    width: 80%;
    font-size: 30px;
    line-height: 40px;
    padding: 30px;
    margin-top: 15px;
  }

  .strong {
    font-weight: bold;
    text-decoration: underline;
  }

  @media (max-width: 640px) {
    font-size: 40px;
    padding: 40px;
    line-height: 46px;
  }
}
