@import '../../global-settings';

.button {
  @include body-text;
  font-size: 50px;
  padding: 40px 30px 60px;
  text-transform: capitalize;
  border-radius: 20px;
  width: 500px;
  border:black;
  height: 60px;

  &.teamThunder {
    background: $color-thunder;
    box-shadow: -10px 6px 5px $color-thunder-shadow;
    //text-shadow: -5px 6px 5px $color-thunder-shadow;
  }

  &.teamLightning {
    background:$color-lightning;
    box-shadow: -10px 6px 5px $color-lightning-shadow;

    //text-shadow: -5px 6px 5px $color-lightning-shadow;
  }
}

