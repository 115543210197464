@import '../../global-settings';

.wrapper {
  width: 100%;
  position: relative;
}

.boxContainer {
  width: 80%;
  margin-left: 10%;
  padding: 25px 0;

  &.correct {
    background-color: $color-correct-background;
  }
  &.partiallyCorrect {
    background-color: $color-partially-correct-background;
  }
 &.incorrect {
    background-color: $color-incorrect-background;

   .title {
     color: $color-white;
   }
  }

  .title {
    @include header-small;
    font-size: 80px;
    line-height: 80px;
    margin: 0;
    text-align: center;
    color: $color-dark-grey;

    @media (max-width: 640px) {
      font-size: 30px;
      line-height: 34px;
    }
  }

}

.leftSide {
  position: absolute;
  height: 70%;
  width: 10%;
  clip-path: polygon(100% 0%, 84% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
  left: 0;
  top: 15%;
  z-index: -1;
  transform: rotateY(180deg);

  &.correct {
    background-color: $color-correct;
  }
  &.partiallyCorrect {
    background-color: $color-partially-correct;
  }
  &.incorrect {
    background-color: $color-incorrect;
  }

}
.rightSide {
  position: absolute;
  height: 70%;
  width: 10%;
  clip-path: polygon(100% 0%, 84% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
  right: 0;
  top: 15%;
  z-index: -1;

  &.correct {
    background-color: #3F826D;
  }
  &.partiallyCorrect {
    background-color: #006DAA;
  }
  &.incorrect {
    background-color: #C83738;
  }
}
