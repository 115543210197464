@import '../../global-settings';

.inputField {
  @include header-small;
  font-size: 32px;
  line-height: 32px;
  background: $color-white;
  color: $color-dark-grey;
  border: 3px solid $color-light-grey;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 25px;
  height: 329px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;

  &::placeholder {
    color: $color-light-grey;
  }
}
