@import '../../global-settings';
.zoinksHeaderWrapper {
  background-color: $color-teacher-background-boxes;
  width: 100%;
}

.teamRed {
  background-color: #eb6b6f;
}

.teamBlue {
  background-color: #5b5bbe;
}

.logoWrapper {
  padding: 25px;
}

.teamNameWrapper {
  color: $color-white;
  padding: 25px;
  text-align: right;

  @media (max-width: 640px) {
    h2 {
      margin-top: 0;
    }
  }
}
