@import '../../global-settings';
.container {
  color: $color-dark-grey;
  margin: 10px 0;
  padding: 20px 30px;
  width: 100%;
  text-align: center;
  border-radius: 5px;

  h2 {
    margin-top: 25px;
    font-size: 18px;
    font-weight: lighter;
  }

  h1 {
    font-size: 25px;
    font-weight: bold;
  }
}

.answerBox {
  background-color: white;
  border-radius: 5px;
  color: grey;
  font-size: 22px;
  line-height: 26px;
  width: 100%;
  max-height: 200px;
  padding: 20px;
  text-align: center;

  p {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (max-width: 640px) {
    p {
      font-size: 19px;
      line-height: 24px;
      margin: 0;
    }
  }
}
