@import '../../global-settings';
.zoinksHeaderWrapper {
  background-color: $color-background;
  width: 100%;
}

.logoWrapper {
  padding: 25px;
}

.gameCodeReferenceTitle {
  @include body-text;
  padding: 0;
  margin: 0;
  color: $color-white;
  font-size: 22px;
  line-height: 22px;
}

.gameCodeReference {
  @include header-large;
  padding: 0;
  letter-spacing: 2px;
  margin: 0;
  color: $color-white;
}

.teamNameWrapper {
  color: $color-white;
  padding: 25px;
  padding-top: 35px;
  text-align: right;
}

.launch {
  @include button;
  font-family: $primary-sans-serif-font;
  background: $color-primary;
  border: 1px solid $color-primary;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
  color: $color-secondary;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 7px 1px 0 $color-secondary;
  float: right;
  margin-right: 10px;
  margin-top: 0;

  &:hover {
    box-shadow: 0 7px 1px 0 $color-white;
  }
}
