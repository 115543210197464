@import '../../global-settings';

.body {
  position: relative;
  display: block;
  width: 100%;
  //background-color: $color-teacher-background-boxes;
  border-radius: 15px;
  //padding: 30px 30px 0;
}


.inputField {
  @include header-small;
  width: 45%;
  background: $color-white;
  color: $color-light-grey;
  border: 5px solid $color-light-grey;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 32px;
  padding: 10px 0;
  text-align: center;
  margin-bottom: 30px;


  &::placeholder {
    color: $color-light-grey;
  }
}
.inputFieldSec {
  @include header-small;
  width: 45%;
  background: $color-white;
  color: $color-light-grey;
  border: 5px solid $color-light-grey;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 32px;
  padding: 10px 0;
  text-align: center;
  margin-bottom: 30px;
  margin-left: 10px;


  &::placeholder {
    color: $color-light-grey;
  }
}

