@import '../../global-settings';
.wrapper {
  max-width: 230px;
  cursor: pointer;
}
.studentName {
  @include header-small;
  font-size: 18px;
  position: relative;
  color: $color-white;
  text-align: center;
  margin: 0;
  z-index: 3;
  text-transform: capitalize;
}

.nameCard {
  position: relative;
  background-color: $color-dark-grey;
  padding: 15px;
  border-radius: 13px;
  top: -80px;
  z-index: 2;

  @media screen and (min-width: 1024px) {
    padding: 25px;

    p {
      font-size: 25px;
    }
  }
}
.hoverEdit {
  display: block;
  position: relative;
  top: -12rem;
  margin-top: 10px;
  z-index: 2;

  .editButton {
    background-color: #00ce00;
    border: 1px solid black;
    color: white;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    padding: 5px 10px;
  }

  .deleteButton {
    background-color: #ce0000;
    border: 1px solid black;
    color: white;
    width: 100%;
    padding: 5px 10px;
    border-radius: 0 0 10px 10px;
  }
}

.avatar {
  width: 100px;
  z-index: 2;
  position: relative;
  top: -31px;
  display: block;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    height: 150px;
    width: 150px;
    top: -10px;
  }
}

.icon {
  font-size: 25px;
  color: black;
  text-align: center;
  margin: 0;
}

.pulsatingCircle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: '';
    position: relative;
    display: block;
    z-index: 1;
    width: 360%;
    height: 360%;
    box-sizing: border-box;
    margin-left: -40px;
    margin-top: -40px;
    border-radius: 100px;
    background-color: #ffffff;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

.notSelectable {
  animation: fade-in 1s forwards;
}

.isCenter {
  margin: 0 auto;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
