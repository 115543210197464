@import '../../global-settings';
.container {
  width: 100%;
  max-width: 98%;
  position: relative;
  padding-left: 10px;
}

.teams {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.team {
  flex: 1;
}

.vs {
  @include header-large;
  font-size: 30px;
  padding: 10px 10px 10px 30px;
  color: $color-white;
  background-color: $color-background;
}
