@import '../../global-settings';
.wrapper {
  width:100%;
  display: block;
  margin: 0 auto;
  text-align: center;
  color: $color-white;
}
h3 {
  @include header-small;
  text-align: center;
  padding: 50px;
}
.button {
  font-family: $primary-sans-serif-font;
  width: 100%;
  color: white;
  background: #F6C26D;
  font-size: 32px;

  &:hover {
    animate: pulsate 0.3s;
    background: #ffd085;
  }
}

.avatarButton {
  height: 161px;
  background: transparent;
  outline: none;
  border: none;

}

.students  {
  width: 100%;
  margin-top: 20px;
}

.rowBottom {
  margin-top: 20px;
}

.grid {
  margin-top: 80px;
}
