@import '../../global-settings';

.wrapper {
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  padding: 0;
  box-sizing: border-box;

  &:not(.rounded) {
    background-color: $color-question-background;
    color: $color-dark-grey;
  }
}

.title {
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 24px!important;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
}

.rounded {
  border-radius: 20px;
  background-color: $color-teacher-background-boxes;
  .large {
    color: $color-white;
  }
}

.large {
  @include header-large;
  margin: 0;
  padding: 16px;
  color: $color-dark-grey;
}

.large p {
  font-size: 38px;
  line-height: 42px;
  color: inherit;
}

.large a {
  color: inherit;
}

.large h3 {
  font-size: 50px;
  line-height: 54px;
  padding: 0;
  margin: 16px 0;
  color: inherit;
}

.large blockquote {
  text-shadow: 1px 2px rgba(255, 255, 255, .25), 2px 2px rgba(255, 255, 255, .25);
  color: inherit;
}

.large img {
  max-width: 80%;
  margin: 0 auto;
}

.large ul, .large ol {
  text-align: left;
  font-size: 38px;
  line-height: 42px;
  margin: 10px 0;
  color: inherit;
}

