@import '../../global-settings';

.wrapper {
  width: 100%;
  text-align: center;
  color: $color-white;
}

.large {
  @include header-small;
  font-size: 80px;
  line-height: 94px;
  color: $color-white;

  @media (max-width: 640px) {
    font-size: 40px;
    line-height: 46px;
  }
}

.subheader {
  @include header-small;
  font-size: 50px;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 0;
  padding-top: 0;

  @media (max-width: 640px) {
    font-size: 30px;
    line-height: 34px;
  }
}
