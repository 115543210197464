@import '../../global-settings';
.teamWrapper {
  @include header-small;
  font-size: 80px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  width: 46%;
  max-width: 600px;
  border: 0;
  display: inline-block;
  z-index: 1;
  padding: 50px 20px 0;
  position: absolute;
  margin-top: 150px;
  height: 60vh;
  bottom: -30vh;
  transition: bottom 2s;

  &.winningTeam {
    bottom: -10%;
  }

  .avatar {
    position: relative;
    width: 33%;
    padding: 16px;

    @media (max-width: 640px) {
      padding: 2px;
    }
  }

  .avatarTop {
    position: absolute;
    width: calc(33% - 16px);
    padding: 16px;
    top: -90px;
    left: 16px;

    @media (max-width: 640px) {
      width: calc(33% - 2px);
      padding: 2px;
      top: -20px;
    }
  }

  .avatarTop2 {
    position: absolute;
    width: calc(33% - 16px);
    padding: 16px;
    top: -90px;
    left: 33%;

    @media (max-width: 640px) {
      width: calc(33% - 2px);
      padding: 2px;
      top: -20px;
    }
  }

  .avatarTop3 {
    position: absolute;
    width: calc(33% - 16px);
    padding: 16px;
    top: -90px;
    left: 66%;

    @media (max-width: 640px) {
      width: calc(33% - 2px);
      padding: 2px;
      top: -20px;
    }
  }

  &.teamThunder {
    background-color: #5B5BBE;
    color: $color-white;
    left: 2%;
  }

  &.teamLightning {
    background-color: #EB6B6F;
    color: $color-white;
    right: 2%;
  }

}
