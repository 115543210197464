@import '../../global-settings';

.image {
  width: 100%;
  max-width: 300px;
  animation: fly 5s infinite ease-in-out;
}

@keyframes fly {
  0% { transform: translate(-300px, 60px) rotate(-1deg); }
  1% { transform: translate(-298px, 58px) rotate(1deg); }
  2% { transform: translate(-300px, 60px) rotate(-1deg); }
  3% { transform: translate(-298px, 58px) rotate(1deg); }
  4% { transform: translate(-300px, 60px) rotate(1deg); }
  5% { transform: translate(-298px, 58px) rotate(-1deg); }
  6% { transform: translate(-300px, 60px) rotate(1deg); }
  7% { transform: translate(-298px, 58px) rotate(-1deg); }
  8% { transform: translate(-300px, 60px) rotate(1deg); }
  9% { transform: translate(-298px, 58px) rotate(-1deg); }
  10% { transform: translate(-300px, 60px) rotate(1deg); }
  11% { transform: translate(-300px, 60px) rotate(1deg); }
  12% { transform: translate(-298px, 58px) rotate(1deg); }
  13% { transform: translate(-300px, 60px) rotate(-1deg); }
  14% { transform: translate(-298px, 58px) rotate(1deg); }
  15% { transform: translate(-300px, 60px) rotate(1deg); }
  16% { transform: translate(-298px, 58px) rotate(-1deg); }
  17% { transform: translate(-300px, 60px) rotate(1deg); }
  18% { transform: translate(-298px, 58px) rotate(-1deg); }
  19% { transform: translate(-300px, 60px) rotate(-2deg); }
  20% { transform: translate(-300px, 60px) rotate(-5deg); }
  100% { transform: translate(200px, -60px) rotate(-10deg); }
}
