@import '../../global-settings';

.wrapper{
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.large {
  @include header-small;
  font-size: 80px;
  line-height: 94px;
  color: $color-white;
}
.tiny {
  @include header-small;
  font-size: 30px;
  line-height: 94px;
  color: $color-white;
}

.small {
  @include header-small;
  font-size: 40px;
  line-height: 47px;
  color: $color-white;
}


