@import '../../global-settings';

.largeButton {
  @include header-small;
  font-size: 26px;
  border-radius: 4px;
  padding: 18px 30px;
  background-color: $color-white;
  border: 0;
  display: inline-block;
  cursor: pointer;

  &.primary {
    background-color: $color-primary;
    color: $color-white;
  }

  &.isDisabled {
    background: #524e4e;
    border-color: #524e4e;
    cursor: not-allowed;
    opacity: 0.8;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
}

.smallButton {
  padding: 15px;

  &.primary {
    @include button;
    font-family: $primary-sans-serif-font;
    background: linear-gradient(0deg, #365deb 0%, #3e8df0 100%);
    border: 1px solid #3e8df0;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    text-transform: none;
    color: $color-white;
    min-height: 50px;
    border-radius: 6px;
  }

  &.secondary {
    @include button;
    font-family: $primary-sans-serif-font;
    background: linear-gradient(180deg, #f19279 0%, #ee6f91 100%);
    border: 1px solid #ee6f91;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    text-transform: none;
    color: $color-white;
    min-height: 50px;
    border-radius: 6px;
  }

  &.isDisabled {
    background: #524e4e;
    border-color: #524e4e;
    cursor: not-allowed;
    opacity: 0.8;
  }
}


.longButton {
  width: 100%;
  padding: 15px;

  &.primary {
    @include button;
    font-family: $primary-sans-serif-font;
    background: linear-gradient(0deg, #365deb 0%, #3e8df0 100%);
    border: 1px solid #3e8df0;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    text-transform: none;
    color: $color-white;
    min-height: 50px;
    border-radius: 6px;
  }

  &.secondary {
    @include button;
    font-family: $primary-sans-serif-font;
    background: linear-gradient(180deg, #f19279 0%, #ee6f91 100%);
    border: 1px solid #ee6f91;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    text-transform: none;
    color: $color-white;
    min-height: 50px;
    border-radius: 6px;
  }

  &.isDisabled {
    background: #524e4e;
    border-color: #524e4e;
    cursor: not-allowed;
    opacity: 0.8;
  }
}
