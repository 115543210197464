.canvasContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  .canvas {
    border: 1px solid #ccc;
    cursor: crosshair;
    background-color: #f0f0f0;
  }

  .controls {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 0.5rem;
    margin-bottom: 1rem;

    .colorPicker {
      width: 30px;
      height: 30px;
      border: none;
      padding: 0;
      cursor: pointer;
    }

    .sizeSlider {
      width: 200px;
    }
  }

  .buttons {
    margin-top: 1rem;

    button {
      padding: 0.5rem 1rem;
      margin-right: 0.5rem;
      background-color: #eee;
      border: none;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
