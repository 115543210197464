@import '../../global-settings';
.container {
  width: 500px;
  //position: relative;
  margin-top: 40px;
}

.teamWrapper {
  @include header-small;
  font-size: 80px;
  border-radius: 40px;
  width: 100%;
  height: 120px;
  margin-left: 18px;
  margin-bottom: 13px;
  border: 0;
  display: inline-block;

  .avatar {
    position: relative;
    width: 150px;
    top: -40%;
    left: 5%;
    padding-right: 40px;
    margin-left: 30px;
  }
  .avatarTwo {
    position: relative;
    width: 150px;
    top: -40%;
    left: 5%;
    padding-right: 40px;
  }
  .avatarThree {
    position: relative;
    width: 150px;
    top: -40%;
    left: 5%;
    padding-right: 40px;
  }

  &.teamThunder {
    background-color: $color-thunder;
    color: $color-white;
    box-shadow: -18px 13px $color-thunder-shadow;
  }

  &.teamLightning {
    background-color: $color-lightning;
    color: $color-white;
    box-shadow: -18px 13px $color-lightning-shadow;
  }

}

.footer {
  position: relative;
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 60px;

  .teamName {
    font-size: 39px;
    margin-top: 16px;
    margin-left: 18px;
    color: $color-white;

  }
}
.answerSection{
  position: relative;
  right: -10px;
  top: 40px;
}

.playersAnswered {
  @include small-text;
  font-size: 25px;
  background-color: $color-teacher-background-boxes;
  color: $color-white;
  font-weight: bold;
  padding-top: 25px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-top: -50px;
  text-align: center;
  display: block;
}

.answersTitle {
  margin-top: 5px;
  color: $color-white;
  text-align: center;
}
