@import '../../global-settings';
.wrapper {
  display: block;
  margin: 0 auto;
}
.timer {
  font-family: $primary-sans-serif-font;
  width: 148px;
  height: 150px;
  color: white;
  background-color: $color-secondary;
  border: 2px solid white;
  font-size: 42px;
  border-radius: 73px;
  display: block;
  padding: 62px 20px 20px;
  text-align: center;
  margin: 0 auto;
  margin-top: 25px;

  span {
    font-size: 24px;
  }
}
.writing {
  padding: 30px;
}
