@import '../../global-settings';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.feedbackZoinks {
  padding: 10px;
  width: 100px;
  border-radius: 0%;
}

.container {
  max-width: 40%;
  margin-right: 60px;
  margin-bottom: -65px;
  float: right;
  margin-top: 2rem;
  letter-spacing: 0.5px;
  transform: translateY(150%);
  transition: transform 500ms ease;
  position: fixed;
  bottom: 0;
  right: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translate(0, 100%);
  transform-origin: bottom right;
  @media screen and (max-width: 1200px) {
    max-width: 50%;
  }

  @media screen and (max-width: 800px) {
    max-width: 80%;
    margin-right: 10px;
  }

  @media screen and (max-width: 500px) {
    max-width: 90%;
    margin-right: 10px;
  }
}

/* Media query for mobile devices */

.container.inactive {
  display: none;
  max-width: 40%;
  margin-right: 60px;
  margin-bottom: 5px;
  float: right;
  margin-top: 2rem;
  letter-spacing: 0.5px;
  height: 80%;
  transition: transform 500ms ease;
  transform: translateY(-5%);

  @media screen and (max-width: 1200px) {
    max-width: 50%;
  }

  @media screen and (max-width: 800px) {
    max-width: 80%;
  }

  @media screen and (max-width: 500px) {
    max-width: 90%;
  }
}

.container.active {
  display: block;
  max-width: 40%;
  margin-right: 60px;
  margin-bottom: -65px;
  float: right;
  margin-top: 2rem;
  letter-spacing: 0.5px;
  height: 625px;
  transition: transform 500ms ease;
  transform: translateY(-5%);
  @media screen and (max-width: 1200px) {
    max-width: 50%;
  }

  @media screen and (max-width: 800px) {
    max-width: 80%;
  }

  @media screen and (min-width: 600px) {
    height: 650px;
  }
  @media screen and (min-width: 850px) {
    height: 700px;
  }
}

img.astronaut,
img.bug,
img.feedbackIcon {
  width: 50px;
  vertical-align: middle;
  border-style: none;
  border-radius: 100%;

  @media screen and (max-width: 600px) {
    width: 30px;
  }
}

.pointer {
  cursor: pointer;
}
/* Styling the msgHeader container */
.msgHeader {
  border: 1px solid #ccc;
  width: 100%;
  height: 10%;
  border-bottom: none;
  display: inline-block;
  background-color: #efefef;
  margin: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .x {
    float: right;
    margin-right: 15px;
  }
}
/* Styling the profile picture */
.msgimg {
  margin-left: 2%;
  float: left;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* styling user-name */
/* Styling the inbox */
.chatPage {
  padding: 0 0 50px 0;
}

.msgInbox {
  border: 1px solid #ccc;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #efefef;
}

.chats {
  padding: 30px 15px 0 25px;
  background-color: white;
}

.msgPage {
  max-height: 500px;
  overflow-y: auto;
}

.options {
  display: flex;
  justify-content: space-evenly;
  .left {
    padding: 5px;
    text-align: center;
    cursor: pointer;
  }

  .right {
    padding: 5px;
    text-align: center;
    cursor: pointer;
  }
}

.feedbackImg {
  float: right;
  position: fixed;
  bottom: 0;
  right: 0;
}

/* Styling the msg-bottom container */
.msgBottom {
  border-top: 1px solid #ccc;
  position: relative;
  height: 11%;
  background-color: rgb(239 239 239);
}
/* Styling the input field */
.inputGroup {
  float: right;
  margin-top: 13px;
  margin-bottom: 6px;

  margin-right: 20px;
  outline: none !important;
  border-radius: 20px;
  width: 61% !important;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.wrapper {
  display: flex;
}

.inputGroup > .formControl {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.formControl {
  border: none !important;
  border-radius: 20px !important;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.inputGroupText {
  background: transparent !important;
  border: none !important;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: b;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-weight: bold !important;
  cursor: pointer;
}

input:focus {
  outline: none;
  border: none !important;
  box-shadow: none !important;
}

.send-icon {
  font-weight: bold !important;
}

/* Styling the avatar  */
.receivedChatsImg {
  display: inline-block;
  width: 50px;
  float: left;
}

.receivedMsg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.receivedMsgInbox {
}

.receivedMsgInbox p {
  background: #efefef none repeat scroll 0 0;
  border-radius: 10px;
  color: #646464;
  font-size: 14px;
  margin-left: 1rem;
  padding: 1rem;
  width: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 5px 2px;

  @media screen and (max-width: 500px) {
    padding: 10px;
    font-size: 13px;
  }
}
p {
  overflow-wrap: break-word;
}

/* Styling the msg-sent time  */
.time {
  color: #777;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.outgoingChats {
  overflow: hidden;
  margin: 26px 20px;

  .time {
    float: right;
  }
}

.outgoingChatsMsg p {
  background-color: #3a12ff;
  background-image: linear-gradient(45deg, #ee087f 0%, #dd2a7b 25%, #9858ac 50%, #8134af 75%, #515bd4 100%);
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px 2px;
}
.outgoingChatsMsg {
  float: right;
}

/* Styling the avatar */
.outgoing-chats-img {
  display: inline-block;
  width: 50px;
  float: right;
  margin-right: 1rem;
}
@media only screen and (max-device-width: 850px) {
  *,
  .time {
    font-size: 14px;
  }
  // img {
  //   width: 65px;
  // }
  .msgHeader {
    height: 5%;
  }
  .msgPage {
    max-height: none;
  }
  .receivedMsgInbox p {
    font-size: 14px;
  }
  .outgoingChatsMsg p {
    font-size: 14px;
  }
}

@media only screen and (max-device-width: 450px) {
  *,
  .time {
    font-size: 14px;
  }
  // img {
  //   width: 65px;
  // }
  .msgHeader {
    height: 5%;
  }
  .msgPage {
    max-height: none;
  }
  .receivedMsgInbox p {
    font-size: 14px;
  }
  .outgoingChatsMsg p {
    font-size: 14px;
  }
}

//emojis
.feedback {
  --normal: #eceaf3;
  --normal-shadow: #d9d8e3;
  --normal-mouth: #9795a4;
  --normal-eye: #595861;
  --active: #f8da69;
  --active-shadow: #f4b555;
  --active-mouth: #f05136;
  --active-eye: #313036;
  --active-tear: #76b5e7;
  --active-shadow-angry: #e94f1d;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  li {
    position: relative;
    border-radius: 50%;
    background: var(--sb, var(--normal));
    box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
    transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
    -webkit-tap-highlight-color: transparent;
    &:not(:last-child) {
      @media screen and (min-width: 200px) and (max-width: 300px) {
        margin-right: 3%;
      }
      @media screen and (min-width: 300px) and (max-width: 400px) {
        margin-right: 4%;
      }
      @media screen and (min-width: 400px) and (max-width: 500px) {
        margin-right: 5%;
      }
      @media screen and (min-width: 500px) and (max-width: 550px) {
        margin-right: 2%;
      }
      @media screen and (min-width: 500px) and (max-width: 600px) {
        margin-right: 4%;
      }
      @media screen and (min-width: 600px) and (max-width: 650px) {
        margin-right: 6%;
      }
      @media screen and (min-width: 650px) and (max-width: 700px) {
        margin-right: 8%;
      }
      @media screen and (min-width: 700px) and (max-width: 800px) {
        margin-right: 0%;
      }
      @media screen and (min-width: 800px) and (max-width: 900px) {
        margin-right: 4%;
      }
      @media screen and (min-width: 900px) and (max-width: 1100px) {
        margin-right: 7%;
      }
      @media screen and (min-width: 1000px) and (max-width: 1200px) {
        margin-right: 10%;
      }
      @media screen and (min-width: 1200px) and (max-width: 1330px) {
        margin-right: 12%;
      }
      @media screen and (min-width: 1330px) and (max-width: 1420px) {
        margin-right: 14%;
      }
      @media screen and (min-width: 1430px) and (max-width: 1520px) {
        margin-right: 15%;
      }
      @media screen and (min-width: 1520px) and (max-width: 1620px) {
        margin-right: 14%;
      }
      @media screen and (min-width: 1620px) and (max-width: 1720px) {
        margin-right: 15%;
      }
      @media screen and (min-width: 1720px) and (max-width: 1820px) {
        margin-right: 16%;
      }
      @media screen and (min-width: 1820px) and (max-width: 1920px) {
        margin-right: 16%;
      }
      @media screen and (min-width: 1920px) {
        margin-right: 18%;
      }
    }
    div {
      width: 40px;
      height: 40px;
      position: relative;
      transform: perspective(240px) translateZ(4px);
      svg,
      &:before,
      &:after {
        display: block;
        position: absolute;
        left: var(--l, 9px);
        top: var(--t, 13px);
        width: var(--w, 8px);
        height: var(--h, 2px);
        transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
      }
      svg {
        fill: none;
        stroke: var(--s);
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        transition: stroke 0.4s;
        &.eye {
          --s: var(--e, var(--normal-eye));
          --t: 17px;
          --w: 7px;
          --h: 4px;
          &.right {
            --l: 23px;
          }
        }
        &.mouth {
          --s: var(--m, var(--normal-mouth));
          --l: 11px;
          --t: 23px;
          --w: 18px;
          --h: 7px;
        }
      }
      &:before,
      &:after {
        content: '';
        z-index: var(--zi, 1);
        border-radius: var(--br, 1px);
        background: var(--b, var(--e, var(--normal-eye)));
        transition: background 0.4s;
      }
    }
    &.angry {
      --step-1-rx: -24deg;
      --step-1-ry: 20deg;
      --step-2-rx: -24deg;
      --step-2-ry: -20deg;
      div {
        &:before {
          --r: 20deg;
        }
        &:after {
          --l: 23px;
          --r: -20deg;
        }
        svg {
          &.eye {
            stroke-dasharray: 4.55;
            stroke-dashoffset: 8.15;
          }
        }
      }
      &.active {
        animation: angry 1s linear;
        div {
          &:before {
            --middle-y: -2px;
            --middle-r: 22deg;
            animation: toggle 0.8s linear forwards;
          }
          &:after {
            --middle-y: 1px;
            --middle-r: -18deg;
            animation: toggle 0.8s linear forwards;
          }
        }
      }
    }
    &.sad {
      --step-1-rx: 20deg;
      --step-1-ry: -12deg;
      --step-2-rx: -18deg;
      --step-2-ry: 14deg;
      div {
        &:before,
        &:after {
          --b: var(--active-tear);
          --sc: 0;
          --w: 5px;
          --h: 5px;
          --t: 15px;
          --br: 50%;
        }
        &:after {
          --l: 25px;
        }
        svg {
          &.eye {
            --t: 16px;
          }
          &.mouth {
            --t: 24px;
            stroke-dasharray: 9.5;
            stroke-dashoffset: 33.25;
          }
        }
      }
      &.active {
        div {
          &:before,
          &:after {
            animation: tear 0.6s linear forwards;
          }
        }
      }
    }
    &.ok {
      --step-1-rx: 4deg;
      --step-1-ry: -22deg;
      --step-1-rz: 6deg;
      --step-2-rx: 4deg;
      --step-2-ry: 22deg;
      --step-2-rz: -6deg;
      div {
        &:before {
          --l: 12px;
          --t: 17px;
          --h: 4px;
          --w: 4px;
          --br: 50%;
          box-shadow: 12px 0 0 var(--e, var(--normal-eye));
        }
        &:after {
          --l: 13px;
          --t: 26px;
          --w: 14px;
          --h: 2px;
          --br: 1px;
          --b: var(--m, var(--normal-mouth));
        }
      }
      &.active {
        div {
          &:before {
            --middle-s-y: 0.35;
            animation: toggle 0.2s linear forwards;
          }
          &:after {
            --middle-s-x: 0.5;
            animation: toggle 0.7s linear forwards;
          }
        }
      }
    }
    &.good {
      --step-1-rx: -14deg;
      --step-1-rz: 10deg;
      --step-2-rx: 10deg;
      --step-2-rz: -8deg;
      div {
        &:before {
          --b: var(--m, var(--normal-mouth));
          --w: 5px;
          --h: 5px;
          --br: 50%;
          --t: 22px;
          --zi: 0;
          opacity: 0.5;
          box-shadow: 16px 0 0 var(--b);
          filter: blur(2px);
        }
        &:after {
          --sc: 0;
        }
        svg {
          &.eye {
            --t: 15px;
            --sc: -1;
            stroke-dasharray: 4.55;
            stroke-dashoffset: 8.15;
          }
          &.mouth {
            --t: 22px;
            --sc: -1;
            stroke-dasharray: 13.3;
            stroke-dashoffset: 23.75;
          }
        }
      }
      &.active {
        div {
          svg {
            &.mouth {
              --middle-y: 1px;
              --middle-s: -1;
              animation: toggle 0.8s linear forwards;
            }
          }
        }
      }
    }
    &.happy {
      div {
        --step-1-rx: 18deg;
        --step-1-ry: 24deg;
        --step-2-rx: 18deg;
        --step-2-ry: -24deg;
        &:before {
          --sc: 0;
        }
        &:after {
          --b: var(--m, var(--normal-mouth));
          --l: 11px;
          --t: 23px;
          --w: 18px;
          --h: 8px;
          --br: 0 0 8px 8px;
        }
        svg {
          &.eye {
            --t: 14px;
            --sc: -1;
          }
        }
      }
      &.active {
        div {
          &:after {
            --middle-s-x: 0.95;
            --middle-s-y: 0.75;
            animation: toggle 0.8s linear forwards;
          }
        }
      }
    }
    &:not(.active) {
      cursor: pointer;
      &:active {
        transform: scale(0.925);
      }
    }
    &.active {
      --sb: var(--active);
      --sh: var(--active-shadow);
      --m: var(--active-mouth);
      --e: var(--active-eye);
      div {
        animation: shake 0.8s linear forwards;
      }
    }
  }
}

@keyframes shake {
  30% {
    transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg))
      rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
  }
  60% {
    transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg))
      rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
  }
  100% {
    transform: perspective(240px) translateZ(4px);
  }
}

@keyframes tear {
  0% {
    opacity: 0;
    transform: translateY(-2px) scale(0) translateZ(0);
  }
  50% {
    transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
  }
  20%,
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
  }
}

@keyframes toggle {
  50% {
    transform: translateY(var(--middle-y, 0))
      scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
  }
}

@keyframes angry {
  40% {
    background: var(--active);
  }
  45% {
    box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
  }
}
