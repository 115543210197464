@import '../../global-settings';
.zoinksHeaderWrapper {
  background-color: $color-teacher-background-boxes;
  width: 100%;
  text-align: center;

  h2 {
    @include body-text;
    font-size: 50px;
    line-height: 60px;
  }
}

.points {
  @include header-small;
  color: $color-primary;
  font-size: 50px;
  line-height: 60px;
}

.textWhite {
  color: white !important;

  @media (max-width: 500px) {
    font-size: 25px !important;
  }
}

.points {
  @media (max-width: 500px) {
    font-size: 25px !important;
  }
}
