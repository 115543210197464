@import '../../global-settings';
.mainContent {
  display: inline-flex;
  width: 100%;
  margin-top: 63px;

  .timer {
    transform: scale(0.6);
    display: block;
    width: 100%;
  }
}

.zoinkster {
  margin: 0 auto;
  position: relative;
  top: -140px;
  z-index: 0;
}

.chooser {
  height:150px;
  width: 100%;
  margin-top: 150px;
}

.avatar {
  width: 100px;
  display: block;
  margin: 0 auto;
}


.cone {
  height:148px;
  width:148px;
  -webkit-transform: rotate(45deg);
  display: block;
  margin: 0 auto;
  margin-top: 110px;
}
.cone:before {
  width:146px;
  height:74px;
  top: 43px;
  background: rgba(255, 255, 255, 0.87);
  left: 4px;
  -webkit-transform: rotate(-45deg);
  z-index: 1;
  border-radius:50%;
  content: '';
  position: absolute;
  display: block;
}
.cone:after {

  width: 0;
  height: 0;
  top: -60px;
  left: -56px;
  border-bottom: 160px solid rgba(255, 255, 255, 0.47);
  border-left: 73px solid transparent;
  border-right: 73px solid transparent;
  -webkit-transform: rotate(-45deg);
  content: '';
  position: absolute;
  display: block;
}



