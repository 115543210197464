@import '../../global-settings';

.button {
  padding: 20px;
  border-radius: 10px;
  &:hover {
  border: 1px solid #2C3854;
  background-color: #2C3854;
  animation: pulsate 2s infinite;
  }
}



.heading {
  @include header-small;
  color: white;
  display: block;
  text-align: center;
}

.container {
  width: 100%;
  .trees {
    display: block;
    margin: 0 auto;
  }
}
