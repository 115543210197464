@import '../../global-settings';

.selectedAnswer {
  width: 100%;
  text-align: center;
}

.selectedAnswer h3 {
  @include header-small;
  color: $color-white;
}

.answer {
  @include header-large;
  background-color: $color-white;
  color: $color-dark-grey;
  width: 100%;
  border-radius: 4px;
  border: 2px solid $color-dark-grey;
  font-size: 50px;
  line-height: 60px;
  padding: 30px 30px 50px;
  box-sizing: border-box;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  word-wrap: break-word;
}

.answer img.avatar {
  position: absolute;
  bottom: -89px;
  left: calc(50% - 69px);
}

.instruction {
  @include header-small;
  color: $color-white;
  margin-top: 150px;
}

.largeQuestion {
  font-size: 35px;
  margin-bottom: 10px;
  display: block;
}
